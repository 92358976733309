import { useState } from 'react'
import emailjs from 'emailjs-com'


export const Contact = (props) => {
  const initialState = {
    name: '',
    email: '',
    message: '',
  }

  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () =>{ setState({ ...initialState });}

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log("State:" + name, email, message)
    emailjs
      .sendForm('service_c4vfu0p', 'template_xvp5i1l', e.target, '6lMniJq9CgpSUTPia' )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
          document.getElementById("success").style.display="block";
          setInterval(()=>{  document.getElementById("success").style.display="none"}, 2000);        
        },
        (error) => {
          console.log(error.text)
          clearState()
          document.getElementById("failure").style.display="block";
          setInterval(()=>{  document.getElementById("failure").style.display="none"}, 2000);        
        }
      )
  }
  return (
    <div>
      <div id='contact' className='gradient-background'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2> {props.data ? props.data.title : 'loading'} </h2>
                <p> {props.data ? props.data.paragraph : 'loading'} </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        value={name} 
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        value={email}
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    value={message}
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <button type='submit' className='btn btn-custom btn-lg'>
                      Send Message
                    </button>
                  </div>
                  <div className='col-md-8'>
                    <div id='success'>Thank you for your message. We will get back to you as soon as possible! </div>
                    <div id='failure'>Something went wrong. Your message was not sent. Please try again later. </div>
                  </div>
                </div>
               
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Address
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Phone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          {/* <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
