import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Main } from "./components/main";
import { Privacy } from "./components/privacy";
import { Footer } from "./components/footer";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  return (
    <div>
       <BrowserRouter>
        <Navigation />
        <Routes>
          <Route exact path="/" element={<Main />}/>
          <Route exact path="/privacy" element={<Privacy />}/>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
