export const Footer = (props) => {
  
  return (
    <div>
      <div id='footer'>
        <div className='container text-center'>
          <p> &copy; 2022 {props.data ? props.data.company : 'All The Codes .NET'}. Read our <a href="/privacy">privacy policy</a>.</p>
          <p> Design by{' '} <a href='http://www.templatewire.com' rel='nofollow'> TemplateWire </a> </p>
        </div>
      </div>
    </div>
  )
}
